// import Home from '@/views/Home.vue';
const Login = () => import(/* webpackChunkName: "Login" */ '@/views/Login.vue');
const TwoFactor = () => import(/* webpackChunkName: "TwoFactor" */ '@/views/TwoFactor.vue');
const ForgotPassword = () => import(/* webpackChunkName: "ForgotPassword" */ '@/views/ForgotPassword.vue');
const ResetPassword = () => import(/* webpackChunkName: "ResetPassword" */ '@/views/ResetPassword.vue');
const AdminLayout = () => import(/* webpackChunkName: "AdminLayout" */ '@/views/layouts/Admin.vue');
const Customers = () => import(/* webpackChunkName: "Customers" */ '@/views/Customers.vue');
const CustomerCreate = () => import(/* webpackChunkName: "CustomerCreate" */ '@/views/CustomerCreate.vue');
const CustomerEdit = () => import(/* webpackChunkName: "CustomerEdit" */ '@/views/CustomerEdit.vue');
const CustomerShow = () => import(/* webpackChunkName: "CustomerShow" */ '@/views/CustomerShow.vue');
const Users = () => import(/* webpackChunkName: "Users" */ '@/views/Users.vue');
const UserCreate = () => import(/* webpackChunkName: "UserCreate" */ '@/views/UserCreate.vue');
const UserEdit = () => import(/* webpackChunkName: "UserEdit" */ '@/views/UserEdit.vue');
const Terminals = () => import(/* webpackChunkName: "Terminals" */ '@/views/Terminals.vue');
const TerminalCreate = () => import(/* webpackChunkName: "TerminalCreate" */ '@/views/TerminalCreate.vue');
const TerminalEdit = () => import(/* webpackChunkName: "TerminalEdit" */ '@/views/TerminalEdit.vue');
const TerminalShow = () => import(/* webpackChunkName: "TerminalShow" */ '@/views/TerminalShow.vue');
const Agreements = () => import(/* webpackChunkName: "Agreements" */ '@/views/Agreements.vue');
const AgreementCreate = () => import(/* webpackChunkName: "AgreementCreate" */ '@/views/AgreementCreate.vue');
const AgreementEdit = () => import(/* webpackChunkName: "AgreementEdit" */ '@/views/AgreementEdit.vue');
const AgreementShow = () => import(/* webpackChunkName: "AgreementShow" */ '@/views/AgreementShow.vue');
const Transactions = () => import(/* webpackChunkName: "Transactions" */ '@/views/Transactions.vue');
const Messages = () => import(/* webpackChunkName: "Messages" */ '@/views/Messages.vue');
const Actions = () => import(/* webpackChunkName: "Actions" */ '@/views/Actions.vue');
const ActionCreate = () => import(/* webpackChunkName: "ActionCreate" */ '@/views/ActionCreate.vue');
const StepLists = () => import(/* webpackChunkName: "StepLists" */ '@/views/StepLists.vue');
const StepListCreate = () => import(/* webpackChunkName: "StepListCreate" */ '@/views/StepListCreate.vue');
const Client = () => import(/* webpackChunkName: "Client" */ '@/views/Client.vue');
const Dashboard = () => import(/* webpackChunkName: "Dashboard" */ '@/views/Dashboard.vue');
const KYC = () => import(/* webpackChunkName: "KYC" */ '@/views/KYC.vue');
const Client404 = () => import(/* webpackChunkName: "Client404" */ '@/views/Client404.vue');
const NotFound = () => import(/* webpackChunkName: "NotFound" */ '@/views/404.vue');
const Bank = () => import(/* webpackChunkName: "Bank" */ '@/views/Bank.vue');
const Support = () => import(/* webpackChunkName: "Support" */ '@/views/Support.vue');
const AccountSettlements = () => import(/* webpackChunkName: "AccountSettlements" */ '@/views/AccountSettlements.vue');

const routes = [
	{
		path: '/login',
		name: 'Login',
		component: Login,
	},
	{
		path: '/two-factor',
		name: 'TwoFactor',
		component: TwoFactor,
	},
	{
		path: '/forgot-password',
		name: 'ForgotPassword',
		component: ForgotPassword,
	},
	{
		path: '/reset-password',
		name: 'ResetPassword',
		component: ResetPassword,
	},
	{
		path: '/client',
		name: 'Client',
		component: Client,
	},
	{
		path: '/client/404',
		name: 'Client404',
		component: Client404,
	},
	{
		path: '/bank',
		name: 'Bank',
		component: Bank,
	},
	{
		path: '/',
		component: AdminLayout,
		children: [
			{
				path: '/',
				name: 'Dashboard',
				component: Dashboard,
			},
			{
				path: '/agreements',
				name: 'Agreements',
				component: Agreements,
			},
			{
				path: 'agreements/create/:customerId?',
				name: 'AgreementCreate',
				component: AgreementCreate,
			},
			{
				path: 'agreement/:id',
				name: 'AgreementShow',
				component: AgreementShow,
			},
			{
				path: 'agreement/:id/edit',
				name: 'AgreementEdit',
				component: AgreementEdit,
			},
			{
				path: '/customers',
				alias: 'customers',
				name: 'Customers',
				component: Customers,
			},
			{
				path: 'customers/create',
				name: 'CustomerCreate',
				component: CustomerCreate,
			},
			{
				path: 'customer/:id',
				name: 'CustomerShow',
				component: CustomerShow,
			},
			{
				path: 'customer/:id/edit',
				name: 'CustomerEdit',
				component: CustomerEdit,
			},
			{
				path: 'terminals',
				name: 'Terminals',
				component: Terminals,
			},
			{
				path: 'terminals/create',
				name: 'TerminalCreate',
				component: TerminalCreate,
			},
			{
				path: 'terminals/:id/edit',
				name: 'TerminalEdit',
				component: TerminalEdit,
			},
			{
				path: 'terminals/:id',
				name: 'TerminalShow',
				component: TerminalShow,
			},
			{
				path: 'transactions',
				name: 'Transactions',
				component: Transactions,
			},
			{
				path: 'settlements',
				name: 'AccountSettlements',
				component: AccountSettlements,
			},
			{
				path: 'messages',
				name: 'Messages',
				component: Messages,
			},
			{
				path: 'actions',
				name: 'Actions',
				component: Actions,
			},
			{
				path: 'action/create',
				name: 'ActionCreate',
				component: ActionCreate,
			},
			{
				path: 'action/:id/edit',
				name: 'ActionEdit',
				component: ActionCreate,
			},
			{
				path: 'step-lists',
				name: 'StepLists',
				component: StepLists,
			},
			{
				path: 'step-list/:id/edit',
				name: 'StepListEdit',
				component: StepListCreate,
			},
			{
				path: 'step-list/create',
				name: 'StepListCreate',
				component: StepListCreate,
			},
			{
				path: 'users',
				name: 'Users',
				component: Users,
			},
			{
				path: 'user/create',
				name: 'UserCreate',
				component: UserCreate,
			},
			{
				path: 'user/:id/edit',
				name: 'UserEdit',
				component: UserEdit,
			},
			{
				path: 'kyc',
				name: 'KYC',
				component: KYC,
			},
			{
				path: 'support',
				name: 'Support',
				component: Support,
			},
		],
	},
	{
		path: '*',
		name: 'NotFound',
		component: NotFound,
	},
];

export default routes;
